import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/Fade In Section Down To Up/FadeInSectionDownToUp'
import { OurGovernanceData } from '../../Components/Our Governance/OurGovernanceData'
import { LiaLinkedin } from 'react-icons/lia'

export default function OurGovernance() {
    return (
        <div>
            <div>
                <img className='w-full' src='https://ik.imagekit.io/mhcockpit1/MH%20Agro/Our%20Governance/our-gor.jpg' alt='Our Governance' />
            </div>
            <div className="py-10">
                <FadeInSectionDownToUp>
                    <h1 className="text-3xl text-center font-bold text-[#0fe934]">Our Governance</h1>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className="pt-5 text-justify px-80 xl:px-80 sm:px-40 max-sm:px-5">
                        At <span className='text-[#0fe934] font-semibold'>MH Agro</span>, Our Governance framework is crafted to foster innovation, operational excellence, and sustainability within the agricultural industry. Our leadership team, composed of seasoned professionals from a variety of backgrounds, is dedicated to offering strategic guidance, oversight, and support to guarantee the effective deployment of our advanced <span className='text-[#0fe934] font-semibold'>agricultural</span> solutions.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 max-sm:grid-cols-1 px-20 max-sm:px-10 gap-5 py-10'>
                {/* {OurGovernanceData.map((image, index) => (
                    <FadeInSectionDownToUp>
                        <div key={index} className='shadow-lg rounded-3xl hover:scale-105 transition-transform duration-700 ease-in-out'>
                            <img src={image.src} alt='Our Governance' className='rounded-t-3xl' />
                            <div className='py-2 text-center'>
                                <p>{image.name}</p>
                                <p>{image.role}</p>
                            </div>
                        </div>
                    </FadeInSectionDownToUp>
                ))} */}
                {OurGovernanceData.map((image, index) => (
                    <FadeInSectionDownToUp key={index}>
                        <div className='relative shadow-lg rounded-3xl hover:scale-105 transition-transform duration-700 ease-in-out'>
                            <img src={image.src} alt='Our Governance' className='rounded-t-3xl' />
                            {image.linkedin && ( // Check if the LinkedIn property exists
                                <a href={image.linkedin} target="_blank" rel="noopener noreferrer" className='absolute top-2 right-2'>
                                    <LiaLinkedin size={35} />
                                </a>
                            )}
                            <div className='py-2 text-center'>
                                <p>{image.name}</p>
                                <p>{image.role}</p>
                            </div>
                        </div>
                    </FadeInSectionDownToUp>
                ))}
            </div>
        </div>
    )
}
