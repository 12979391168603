import React, { useState, useEffect, useRef } from 'react';
import './FadeInSectionRightToLeft.css';

const FadeInSectionRightToLeft = ({ children }) => {
    const [isVisible, setVisible] = useState(false);
    const domRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                setVisible(entry.isIntersecting);
            });
        });

        const { current } = domRef;
        observer.observe(current);

        return () => {
            observer.unobserve(current);
        };
    }, []);

    return (
        <div
            className={`fade-in-section-right-to-left ${isVisible ? "is-visible-right-to-left" : ""}`}
            ref={domRef}
        >
            {children}
        </div>
    );
};

export default FadeInSectionRightToLeft;
