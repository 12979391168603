import React from 'react';
import emailjs from 'emailjs-com';

const ContactUs = () => {
    const sendEmail = (e) => {
        e.preventDefault();

        const serviceID = 'service_nuqgc84';
        const templateID = 'template_8kwcfvw';
        const apiKey = '1A89Zn5cgeBNEZznx';

        emailjs.init(apiKey);

        emailjs.sendForm(serviceID, templateID, e.target)
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
            }, (error) => {
                console.log(error.text);
                alert('Failed to send the message, please try again.');
            });

        e.target.reset();
    };

    return (
        <div className="w-full text-center overflow-x-hidden">
            <div className="relative group">
                <div className="w-full bg-cover bg-center h-96 group-hover:blur-sm transition-all duration-1000 ease-in-out" style={{ backgroundImage: `url('https://ik.imagekit.io/mhcockpit1/MH%20Agro/Contact%20Us/Contact%20Us%20Cover.jpg?updatedAt=1727154602954')` }}>
                </div>
                <div className="relative top-[-150px] text-white flex max-sm:block flex-wrap justify-around mx-40 xl:mx-40 sm:mx-10 max-sm:mx-0 mb-5 scale-95 group-hover:scale-100 transition-all duration-1000 ease-in-out" style={{ backgroundImage: `url('https://ik.imagekit.io/mhcockpit1/MH%20Agro/Contact%20Us/Conatct%20us%20Inside.jpg?updatedAt=1727154601946')` }}>
                    <div className="flex-2 p-10 shadow-lg transition-transform duration-300 mb-5 w-1/2 max-sm:w-full">
                        <h3 className="text-left text-xl mb-10">Reach Out to Us!</h3>
                        <div className="text-left">
                            <h4 className="text-lg mb-2">Address</h4>
                            <p className="text-sm">Featherlite building, 200 Feet Radial Rd,<br />Iswarya Nagar, Raja J Joseph Colony,<br />Pallavaram, Chennai - 600043.</p>
                            <div className="mt-10 flex">
                                <div>
                                    <p className="text-sm mb-2">Phone: +918845767689</p>
                                    <p className="text-sm">Email: info@mhcockpit.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 bg-black text-white p-10 shadow-lg transition-transform duration-300">
                        <h3 className="text-center text-xl mb-5">Get In Touch</h3>
                        <form onSubmit={sendEmail}>
                            <div className="mb-4 text-left">
                                <label htmlFor="name" className="block mb-1">Name</label>
                                <input type="text" id="name" name="name" className="w-full p-2 rounded-lg bg-gray-900" required />
                            </div>
                            <div className="mb-4 text-left">
                                <label htmlFor="email" className="block mb-1">Email</label>
                                <input type="email" id="email" name="email" className="w-full p-2 rounded-lg bg-gray-900" required />
                            </div>
                            <div className="mb-4 text-left">
                                <label htmlFor="message" className="block mb-1">Message</label>
                                <textarea id="message" name="message" rows="4" className="w-full p-2 rounded-lg bg-gray-900" required></textarea>
                            </div>
                            <button type="submit" className="w-full py-2 bg-gray-900 text-white rounded-lg border-2 border-black hover:bg-green-500 transition-colors">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.305692585349!2d80.16034307512247!3d12.952280487361296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52675aeb55363f%3A0x491352dd8672f0dd!2sMH%20Cockpit%20-%20Aviation%20Academy!5e0!3m2!1sen!2sin!4v1712053178519!5m2!1sen!2sin" width="100%" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='MH Cockpit'></iframe>
        </div>
    );
};

export default ContactUs;
