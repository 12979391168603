import React from 'react';
import { ServiceDetails } from '../../../Components/Services/ServiceDetails';
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/Fade In Section Down To Up/FadeInSectionDownToUp';
import { useParams } from 'react-router-dom';

const ServiceDetailPage = ({ serviceKey }) => {
  const service = ServiceDetails[serviceKey];

  if (!service) return <div>Service not found</div>;

  return (
    <div>
      <div>
        <img src={service.imgSrc} alt={`${service.title} ${service.titleSpan}`} />
      </div>
      <div className='py-14 max-sm:py-10 pl-24 max-sm:pl-5'>
        <FadeInSectionDownToUp>
          <h1 className="text-6xl max-sm:text-2xl font-thin">{service.title} <span className='font-semibold text-[#0fe934]'>{service.titleSpan}</span></h1>
          <p className="text-2xl max-sm:text-lg font-thin">{service.subTitle} <span className='text-[#0fe934]'>{service.subTitleSpan}</span></p>
        </FadeInSectionDownToUp>
      </div>
      <div className="text-justify px-24 max-sm:px-5">
        <FadeInSectionDownToUp>
          <p>{service.intro}</p>
        </FadeInSectionDownToUp>
      </div>
      <FadeInSectionDownToUp>
        <div className="grid grid-cols-3 gap-4 max-sm:gap-2 px-72 xl:px-72 sm:px-40 max-sm:px-5 py-10">
          {service.gridImages.map((image, index) => (
            <img key={index} src={image.src} alt={`${service.title} ${service.titleSpan}`} className="rounded-xl shadow w-full h-full" />
          ))}
        </div>
      </FadeInSectionDownToUp>
      <div className="text-center py-20 max-sm:py-10">
        <FadeInSectionDownToUp>
          <h3 className="text-3xl font-semibold">{service.whatIs} <span className="text-[#0fe934]">{service.whatIsSpan}</span></h3>
        </FadeInSectionDownToUp>
        <FadeInSectionDownToUp>
          <p className="px-80 xl:px-80 sm:px-40 max-sm:px-5 pt-8">{service.whatIsIntro}</p>
        </FadeInSectionDownToUp>
      </div>
      <div className='flex max-sm:block justify-center items-center gap-10 px-24 xl:px-24 sm:px-5 max-sm:px-5 py-10 max-sm:space-y-10'>
        <div className='w-full'>
          <img src={service.circleImg} alt={`${service.title} ${service.titleSpan}`} />
        </div>
        <div className='w-full'>
          <div className='space-y-7'>
            {service.colourBoxes.map((box, index) => (
              <div key={index} className={`bg-[${box.bgColour}] rounded-3xl pl-20 xl:pl-20 sm:pl-10 max-sm:pl-10 pr-40 xl:pr-40 sm:pr-10 max-sm:pr-10 py-0.5`}>
                <div className="bg-white px-8 max-sm:px-5 py-10 xl:py-10 sm:py-5 max-sm:py-5 rounded-3xl shadow-xl">
                  <FadeInSectionDownToUp>
                    <h4 className="font-semibold">{box.title}</h4>
                    <p className="text-gray-600">{box.description}</p>
                  </FadeInSectionDownToUp>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-black py-7 my-10">
        <FadeInSectionDownToUp>
          <h3 className="text-3xl text-center font-bold text-[#008000]">{service.blackBoxHeading}</h3>
        </FadeInSectionDownToUp>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-20 xl:gap-20 sm:gap-10 pt-8 px-20 xl:px-20 sm:px-10 max-sm:px-10">
          {service.blackBoxGrid.map((item, index) => (
            <div key={index} className="text-white hover:-translate-y-3 transition-all duration-700 ease-in-out">
              <FadeInSectionDownToUp>
                <h4 className="text-2xl font-semibold py-5">{item.title}</h4>
                <p className="text-justify">{item.description}</p>
              </FadeInSectionDownToUp>
              <hr className='bg-[#008000] border-[#008000] p-0.5 mt-20' />
            </div>
          ))}
        </div>
      </div>
      <div className="py-10">
        <FadeInSectionDownToUp>
          <h3 className="text-3xl text-center font-semibold text-[#008000]">{service.ourCommitment} <span className="text-black">{service.ourCommitmentSpan}</span></h3>
        </FadeInSectionDownToUp>
        <FadeInSectionDownToUp>
          <p className="pt-5 text-justify px-80 xl:px-80 sm:px-40 max-sm:px-5">{service.ourCommitmentIntro}</p>
        </FadeInSectionDownToUp>
      </div>
      <div className="py-10">
        <FadeInSectionDownToUp>
          <h3 className="text-3xl text-center font-semibold text-[#008000]">{service.join} <span className="text-black">{service.joinSpan1}</span> <span className="text-[#008000]">{service.joinSpan2}</span></h3>
        </FadeInSectionDownToUp>
        <FadeInSectionDownToUp>
          <p className="pt-5 text-justify px-80 xl:px-80 sm:px-40 max-sm:px-5">{service.joinIntro}</p>
        </FadeInSectionDownToUp>
      </div>
    </div>
  );
};

export const ServicesDetailedPages = () => {
  const { serviceKey } = useParams();

  return <ServiceDetailPage serviceKey={serviceKey} />;
};