export const OurGovernanceData = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/CEO.jpg",
        name: "Ahamed Subair",
        role: "Founder Chairman",
        linkedin: "https://www.linkedin.com/in/ahamed-subair-142255aa/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
    },
    // {
    //     src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Ashia%20-%20Director%20HR.jpg",
    //     name: "Ashia",
    //     role: "Director HR"
    // },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/yasir%20sir.jpg",
        name: "Ahamed Yasir",
        role: "CEO"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Haja.jpg",
        name: "Haja",
        role: "Director Advisory Board"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Jehath.jpg",
        name: "Jehad Al Shehri",
        role: "Director - GCC operations"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Alhaf%20Malik%20New.webp",
        name: "Alhaf Malik",
        role: "CTO"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/Burhan%20Mohamed.jpg",
        name: "Burhan Mohamed",
        role: "COO - GCC operations"
    },

]