import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css';
import FadeInSectionLeftToRight from '../../Components/FadeAnimations/Fade In Section Left To Right/FadeInSectionLeftToRight';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/Fade In Section Down To Up/FadeInSectionDownToUp';
import FadeInSectionRightToLeft from '../../Components/FadeAnimations/Fade In Section Right To Left/FadeInSectionRightToLeft';
import Innovation from '../../Components/Home/Innovation';

export default function Home() {

    const MachinesAndTools = [

        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/autonomous-tractor1.jpg",
            title: "Autonomous Tractors.",
            description: "Enhancing efficiency and reducing labor costs through precise field operations.",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/robotics-harvest.png",
            title: "Robotic Harvesters",
            description: "Ensuring optimal harvest with automated harvesting processes.",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/seeding-plant.png",
            title: "Seeding and Planting Robots",
            description: "Ensuring consistent seed placement for uniform crop growth.",
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/drone-tool.jpg",
            title: "Agricultural Drones",
            description: "Offering aerial monitoring and precise fertilizing for improved crop management.",
        },

    ]

    const HowMHAgroDiffers = [

        {
            badge: "01",
            title: "Advanced Precision Farming Techniques",
            description: "Utilizing GPS-guided machinery and VRT for optimal resource use, maximizing crop yields and sustainability."
        },
        {
            badge: "02",
            title: "Comprehensive Remote Sensing and Monitoring",
            description: "Providing real-time field data through satellite imaging, drone surveillance, and IoT sensors for proactive farm management."
        },
        {
            badge: "03",
            title: "Innovative Smart Irrigation Systems",
            description: "Conserving water with automated irrigation solutions, soil moisture sensors, and weather-based scheduling."
        },
        {
            badge: "04",
            title: "Robust Farm Management Software",
            description: "Streamlining operations with comprehensive tools for crop planning, inventory management, and financial oversight."
        },
        {
            badge: "05",
            title: "State-of-the-Art Agricultural Drones",
            description: "Enhancing field management with drones for precise crop spraying, field mapping, and pest and disease detection."
        },
        {
            badge: "06",
            title: "Sustainable Greenhouse Automation",
            description: "Optimizing indoor farming with climate control systems, automated nutrient delivery, and more."
        }

    ]

    return (
        <div>
            <div>
                <AliceCarousel
                    autoPlay
                    disableButtonsControls
                    disableDotsControls
                    autoPlayInterval={2000}
                    buttonsDisabled={false}
                    dotsDisabled={true}
                    infinite={true}
                    swipeable={true}
                    draggable={true}
                    responsive={{
                        0: { items: 1 },
                        568: { items: 1 },
                        1024: { items: 1 },
                    }}
                >
                    <div><img src="https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/home-hbg.jpg" alt="homecover" preload="auto" /></div>
                    <div><img src="https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/home-hbg%202.jpg" alt="homecover" preload="auto" /></div>
                    <div><img src="https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/home-hbg3.jpg" alt="homecover" preload="auto" /></div>
                </AliceCarousel>
            </div>
            <div className='flex justify-center items-center'>
                <img src='https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/MHhomeimg-removebg-preview.png' alt='MH Agro' />
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className='px-40 max-sm:px-10 text-justify'>
                        MH Agro leads agricultural innovation by empowering farmers with advanced techniques and expertise. Our mission is to enhance productivity, reduce costs, and promote sustainable farming practices. With a dedicated team of experts, we provide comprehensive support to optimize agricultural efficiency and sustainability.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className='pt-20 text-4xl font-semibold flex justify-center items-center space-x-2'>
                        <span>Why Choose</span><span className='text-[#18531C]'>MHAgro?</span>
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex xl:flex sm:block max-sm:block justify-center items-center gap-5 px-28 xl:px-28 sm:px-14 max-sm:px-10 py-8'>
                <FadeInSectionLeftToRight>
                    <div className='shadow-xl p-8 h-[250px] xl:h-[250px] sm:h-auto max-sm:h-auto hover:scale-105 transition-transform duration-700 ease-in-out rounded-xl'>
                        <p className='text-2xl text-[#18531C] font-semibold'>
                            Innovative and Sustainable
                        </p>
                        <p className='pt-3'>
                            Revolutionizing agriculture with groundbreaking methods, ensuring sustainability and progress for future generations. Uniting innovation and sustainability seamlessly.
                        </p>
                    </div>
                </FadeInSectionLeftToRight>
                <FadeInSectionDownToUp>
                    <div className='shadow-xl p-8 h-[250px] xl:h-[250px] sm:h-auto max-sm:h-auto hover:scale-105 transition-transform duration-700 ease-in-out rounded-xl'>
                        <p className='text-2xl text-[#18531C] font-semibold'>
                            Precision Farming
                        </p>
                        <p className='pt-3'>
                            Utilizing advanced technology for precise cultivation, optimizing resources, maximizing yield, and ensuring environmental conservation. Precision farming: farming perfected.
                        </p>
                    </div>
                </FadeInSectionDownToUp>
                <FadeInSectionRightToLeft>
                    <div className='shadow-xl p-8 h-[250px] xl:h-[250px] sm:h-auto max-sm:h-auto hover:scale-105 transition-transform duration-700 ease-in-out rounded-xl'>
                        <p className='text-2xl text-[#18531C] font-semibold'>
                            Eco-friendly Initiatives
                        </p>
                        <p className='pt-3'>
                            Championing eco-conscious practices, preserving nature's balance, reducing carbon footprint, and fostering a greener, healthier planet. Eco-friendly initiatives for a sustainable tomorrow.
                        </p>
                    </div>
                </FadeInSectionRightToLeft>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className='py-20 max-sm:py-5 text-4xl max-sm:text-3xl max-sm:text-center font-semibold flex justify-center items-center'>
                        Innovation that Transforms Agricluture
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div>
                <Innovation />
            </div>
            <div className='flex max-sm:block justify-center items-center xl:gap-10 sm:gap-10 px-20 max-sm:px-5 py-10'>
                <div className='w-full'>
                    <FadeInSectionLeftToRight>
                        <img className='rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/tot-tire.png' alt='MH Agro' />
                    </FadeInSectionLeftToRight>
                </div>
                <div className='w-full'>
                    <FadeInSectionRightToLeft>
                        <p className='font-semibold text-3xl max-sm:text-2xl py-2'>
                            Revolutionizing Automated<br />
                            Farming Solutions
                        </p>
                        <p className='py-2 text-justify'>
                            At MH Agriculture, we are dedicated to revolutionizing the agricultural industry with state-of-the-art technology solutions. Our mission is to drive innovation and efficiency across the entire agricultural supply chain, from precision cultivation to seamless distribution.
                        </p>
                        <p className='py-1'>
                            <span>&#x2705;</span>Superior Tech Standards
                        </p>
                        <p className='py-1'>
                            <span>&#x2705;</span>Efficient Farming Products
                        </p>
                    </FadeInSectionRightToLeft>
                </div>
            </div>
            <div className='py-10'>
                <FadeInSectionDownToUp>
                    <p className='text-center font-bold text-4xl py-2'>
                        Our Machines and Tools
                    </p>
                    <p className='text-center font-semibold text-4xl max-sm:text-2xl py-2'>
                        Innovating <span className='text-[#18531C]'>Agriculture</span> with Advanced Technology
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-10 max-sm:py-5 px-20 xl:px-20 sm:px-10 max-sm:px-0'>
                <AliceCarousel
                    autoPlay
                    disableButtonsControls
                    disableDotsControls
                    autoPlayInterval={2000}
                    buttonsDisabled={false}
                    dotsDisabled={true}
                    infinite={true}
                    swipeable={true}
                    draggable={true}
                    responsive={{
                        0: { items: 1 },
                        568: { items: 2 },
                        1024: { items: 3 },
                    }}
                >
                    {MachinesAndTools.map((image, index) => (
                        <div key={index} className='px-5'>
                            <div className='border h-80 xl:h-80 sm:h-full rounded-xl'>
                                <img className='h-52 w-full object-cover rounded-t-xl' src={image.src} alt='Machines and Tools' />
                                <p className='text-center font-semibold text-xl py-2'>{image.title}</p>
                                <p className='text-center text-md py-2'>{image.description}</p>
                            </div>
                        </div>
                    ))}
                </AliceCarousel>
            </div>
            <div className='py-10'>
                <FadeInSectionDownToUp>
                    <p className='text-center font-bold text-4xl'>
                        How <span className='text-[#18531C] underline underline-offset-[9px]'>MHAgro</span> Differs
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex justify-center items-center py-10'>
                <div className='grid grid-cols-3 xl:grid-cols-3 max-sm:grid-cols-1 px-20 max-sm:px-5 gap-10'>
                    {HowMHAgroDiffers.map((content, index) => (
                        <div className='border rounded-xl shadow-lg hover:shadow-xl transition-all duration-100 ease-in-out' key={index}>
                            <FadeInSectionDownToUp>
                                <div className='p-8'>
                                    <p><span className='bg-[#ffb327] p-1 rounded-md text-white font-semibold'>{content.badge}</span></p>
                                    <p className='font-bold text-[#18531C] text-xl py-3'>{content.title}</p>
                                    <p>{content.description}</p>
                                </div>
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
