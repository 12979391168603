import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/Fade In Section Down To Up/FadeInSectionDownToUp'
import { ServicesBox } from '../../Components/Services/ServiceBox'
import { Link } from 'react-router-dom'

export default function Services() {
    return (
        <div>
            <div>
                <img className='w-full' src='https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/service-bg.jpg' alt='MH Agro' />
            </div>
            <div className='py-20'>
                <FadeInSectionDownToUp>
                    <h1 className='font-bold text-5xl text-[#0fe934] text-center py-5'>Our Services</h1>
                    <p className='text-justify text-lg px-80 xl:px-80 sm:px-20 max-sm:px-5 pt-5'>
                        MH Agro offers advanced <span className='text-[#0fe934] font-semibold'>agricultural</span> solutions to enhance efficiency and sustainability. Our services include precision <span className='text-[#0fe934] font-semibold'>agriculture</span> with GPS-guided machinery, smart irrigation systems, automated machinery for cost reduction, livestock monitoring technology, farm management software, remote sensing with satellite imaging and drones, soil and crop analysis, streamlined supply chain solutions, greenhouse automation, and <span className='text-[#0fe934] font-semibold'>agricultural</span> drones for precise farming tasks.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex justify-center items-center px-40 xl:px-40 sm:px-10 max-sm:px-5 py-10'>
                <div className='grid grid-cols-3 max-sm:grid-cols-1 gap-10 xl:gap-10 sm:gap-5 max-sm:gap-5'>
                    {ServicesBox.map((service) => (
                        <div key={service.id} className='hover:bg-[#9aeb32] hover:shadow-[#9aeb32] hover:shadow-md transition-all duration-700 ease-in-out p-8 rounded-xl group'>
                            <FadeInSectionDownToUp>
                                <Link to={`/service/${service.id}`}>
                                    <div className='flex justify-center items-center py-5'>
                                        <img className='w-32 group-hover:scale-110 transition-all duration-700 ease-in-out' src={service.src} alt={service.title} />
                                    </div>
                                    <p className='text-xl font-semibold py-5'>{service.title}</p>
                                    <p className='text-justify'>{service.description}</p>
                                </Link>
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
