export const ServiceDetails = {

    precisionagriculture: {
        imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Precision%20Farming/PF%2001%20-%20Out%20Cover.jpg",
        title: "Precision",
        titleSpan: "Farming",
        subTitle: "Elevate Your Agriculture with",
        subTitleSpan: "MH Agro",
        intro: "Welcome to the future of agriculture with MH Agriculture's precision farming solutions. Our innovative technologies empower farmers to maximize efficiency, reduce waste, and enhance sustainability. Precision farming is the key to revolutionizing your agricultural practices, boosting productivity, and ensuring environmental stewardship.",
        gridImages: [
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Precision%20Farming/percision-farming1.jpg",
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Precision%20Farming/precision-agri.webp",
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Precision%20Farming/Precision-Agriculture-img%204.webp",
            },
        ],
        whatIs: "What is Precision",
        whatIsSpan: "Farming?",
        whatIsIntro: "Precision farming, or precision agriculture, involves using cutting-edge technologies like GPS, IoT, and advanced data analytics to monitor and manage crop production. By harnessing real-time data, farmers can make informed decisions that lead to increased yields, optimized resource use, and sustainable farming practices.",
        circleImg: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Precision%20Farming/Why%20Choose-Precision-Farming.png",
        colourBoxes: [
            {
                bgColour: "#9ED442",
                title: "Maximize Crop Yields",
                description: "Utilize precise data to ensure optimal plant growth and increase your harvest.",
            },
            {
                bgColour: "#1DFA75",
                title: "Optimize Resource Use",
                description: "Reduce costs by applying water, fertilizers, and pesticides only where and when they are needed.",
            },
            {
                bgColour: "#9ED442",
                title: "Make Informed Decisions",
                description: "Leverage data analytics for real-time insights and better crop management.",
            },
            {
                bgColour: "#1DFA75",
                title: "Promote Sustainability",
                description: "Minimize environmental impact by reducing chemical use and conserving natural resources.",
            },
        ],
        blackBoxHeading: "Precision Farming",
        blackBoxGrid: [
            {
                title: "VRT",
                description: "Variable Rate Technology (VRT) optimizes field inputs such as fertilizers, pesticides, and seeds. By analyzing soil and crop data, VRT enables precise application, reducing waste and enhancing yield. Farmers can achieve sustainability and cost efficiency with tailored input rates, improving overall agricultural productivity.",
            },
            {
                title: "Drone",
                description: "Drones are revolutionizing agriculture by providing real-time data on crop health, water stress, and soil conditions. Farmers use drone technology for precision agriculture, monitoring large fields efficiently and making data-driven decisions to enhance crop yield and minimize resource use.",
            },
            {
                title: "Irrigation",
                description: "Smart irrigation systems optimize water usage in agriculture. By using sensors and data analytics, these systems deliver water precisely where and when needed. This technology helps farmers conserve water, improve crop health, and increase productivity while reducing environmental impact.",
            },
        ],
        ourCommitment: "Our Commitment to",
        ourCommitmentSpan: "Society",
        ourCommitmentIntro: "At MH Agriculture, we are dedicated to driving innovation in agriculture. Our precision farming solutions not only enhance your productivity but also promote sustainable farming practices. By using technology to make data-driven decisions, you can achieve higher efficiency, reduce your environmental footprint, and contribute to a more sustainable future for agriculture.",
        join: "Join the",
        joinSpan1: "Precision Farming",
        joinSpan2: "Revolution",
        joinIntro: "Ready to transform your farming operations? Join the growing number of farmers who are optimizing their water usage with MH Agriculture's smart irrigation systems. Contact us today to learn more and start your journey toward more efficient and sustainable agriculture.",
    },

    smartirrigation: {
        imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Smart%20Irrigation/SIS%2001%20-%20Out%20Cover.jpg",
        title: "Smart",
        titleSpan: "Irrigation Systems",
        subTitle: "Optimize Your Water Usage with",
        subTitleSpan: "MH Agro",
        intro: "Welcome to the future of agriculture with MH Agriculture's smart irrigation systems. Our innovative technologies enable farmers to optimize water usage, enhance crop health, and promote sustainability. Smart irrigation systems are the key to transforming your agricultural practices, improving productivity, and ensuring efficient resource management.",
        gridImages: [
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Smart%20Irrigation/SIS%20-%20S1.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Smart%20Irrigation/SIS%20-%20S2.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Smart%20Irrigation/SIS%20-%20S3.jpg"
            },
        ],
        whatIs: "What is Smart",
        whatIsSpan: "Irrigation Systems?",
        whatIsIntro: "Smart irrigation systems utilize advanced sensors, automation, and data analytics to deliver precise water application to crops. By monitoring soil moisture, weather conditions, and plant needs in real-time, these systems ensure that crops receive the right amount of water at the right time. This leads to efficient water use, reduced waste, and healthier crops.",
        circleImg: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Smart%20Irrigation/SIS%2001%20-%20Out.png",
        colourBoxes: [
            {
                bgColour: "#9ED442",
                title: "Maximize Water Efficiency",
                description: "Use water resources effectively by applying water only when and where it is needed.",
            },
            {
                bgColour: "#1DFA75",
                title: "Enhance Crop Health",
                description: "Ensure optimal hydration for crops, leading to improved growth and yield.",
            },
            {
                bgColour: "#9ED442",
                title: "Reduce Costs",
                description: "Lower water and energy bills by avoiding over-irrigation and minimizing water waste.",
            },
            {
                bgColour: "#1DFA75",
                title: "Promote Sustainability",
                description: "Conserve water and reduce environmental impact by using resources more efficiently.",
            },
        ],
        blackBoxHeading: "Smart Irrigation Systems",
        blackBoxGrid: [
            {
                title: "Automated Drip Irrigation",
                description: "Implement automated drip irrigation systems that deliver water directly to the root zone of plants. This method reduces evaporation and runoff, ensuring that water is used efficiently. Automated drip irrigation systems are ideal for conserving water and promoting healthy crop growth.",
            },
            {
                title: "Soil Moisture Sensors",
                description: "Utilize soil moisture sensors to monitor the water content in the soil continuously. These sensors provide real-time data, allowing you to adjust irrigation schedules based on soil conditions. By preventing over- or under-watering, soil moisture sensors help maintain optimal soil moisture levels for crop health.",
            },
            {
                title: "Weather-Based Controllers",
                description: "Deploy weather-based irrigation controllers that adjust watering schedules based on real-time weather data. These controllers take into account factors such as rainfall, temperature, and humidity to optimize irrigation. Weather-based controllers ensure that crops receive the right amount of water, regardless of changing weather conditions.",
            },
        ],
        ourCommitment: "Our Commitment to",
        ourCommitmentSpan: "Sustainability",
        ourCommitmentIntro: "At MH Agriculture, we are dedicated to promoting sustainable farming practices. Our smart irrigation systems not only boost your productivity but also help you reduce your environmental footprint. By using water resources more efficiently and minimizing waste, you can contribute to a more sustainable future for agriculture.",
        join: "Join the",
        joinSpan1: "Smart irrigation",
        joinSpan2: "Revolution",
        joinIntro: "Embrace the future of agriculture with precision farming techniques. Our innovative solutions help farmers optimize resources, reduce waste, and increase productivity. Join us in revolutionizing agriculture and making a positive impact on the environment. Together, we can achieve sustainable and efficient farming practices for a better future.",
    },

    automatedmachinery: {
        imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Automated%20Machinery/AMachinery%2001%20-%20Out%20Cover.jpg",
        title: "Automated Machinery",
        titleSpan: "Farming",
        subTitle: "Revolutionize Your Farm with",
        subTitleSpan: "MH Agro",
        intro: "Welcome to the forefront of modern agriculture with MH Agriculture's automated machinery. Our cutting-edge technology brings automation to your farm, helping you enhance efficiency, productivity, and sustainability. With automated machinery, you can streamline your farming operations and focus on what matters most: growing healthy, bountiful crops.",
        gridImages: [
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Automated%20Machinery/AMachinery%2001.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Automated%20Machinery/AMachinery%2002.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Automated%20Machinery/AMachinery%2003.jpg"
            },
        ],
        whatIs: "What is Automated Machinery",
        whatIsSpan: "Farming?",
        whatIsIntro: "Automated machinery refers to advanced farming equipment that operates with minimal human intervention, using technologies like GPS, sensors, robotics, and artificial intelligence. These machines are designed to perform a variety of agricultural tasks, such as plowing, planting, and harvesting, with high precision and efficiency. By automating repetitive and labor-intensive processes, automated machinery helps farmers save time, reduce labor costs, and enhance overall productivity. This technology also promotes sustainable farming practices by optimizing resource use and minimizing environmental impact.",
        circleImg: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Automated%20Machinery/AMAchinery%2001%20-%20Out.png",
        colourBoxes: [
            {
                bgColour: "#9ED442",
                title: "Increase Efficiency",
                description: "Automate repetitive tasks to save time and labor.",
            },
            {
                bgColour: "#1DFA75",
                title: "Enhance Precision",
                description: "Perform farming operations with pinpoint accuracy, reducing waste.",
            },
            {
                bgColour: "#9ED442",
                title: "Boost Productivity",
                description: "Maximize output with reliable, high-performance machinery.",
            },
            {
                bgColour: "#1DFA75",
                title: "Sustainability",
                description: "Maximize output with reliable, high-performance machinery.",
            },
        ],
        blackBoxHeading: "",
        blackBoxGrid: [
            {
                title: "Autonomous Tractors",
                description: "Our autonomous tractors are equipped with advanced GPS and sensor technology, allowing them to navigate fields with precision. These tractors can perform tasks such as plowing, planting, and harvesting without human intervention, ensuring consistent and efficient operations.",
            },
            {
                title: "Automated Planters and Seeders",
                description: "Achieve perfect planting with our automated planters and seeders. These machines ensure precise seed placement and depth, optimizing plant growth and yield. Automated planters and seeders reduce labor costs and improve planting efficiency.",
            },
            {
                title: "Robotic Harvesters",
                description: "Speed up your harvesting process with our robotic harvesters. Designed to handle various crops, these machines can quickly and accurately harvest produce, reducing the time and labor required. Robotic harvesters minimize crop damage and increase harvesting efficiency.",
            },
        ],
        ourCommitment: "Our Commitment to",
        ourCommitmentSpan: "Society",
        ourCommitmentIntro: "At MH Agriculture, we are dedicated to promoting sustainable farming practices. Our automated machinery solutions not only increase your productivity but also help you reduce your environmental footprint. By using resources more efficiently and minimizing waste, you can contribute to a more sustainable future for agriculture.",
        join: "Join the",
        joinSpan1: "Automated Farming",
        joinSpan2: "Revolution",
        joinIntro: "Ready to take your farming to the next level? Join the growing number of farmers who are revolutionizing their operations with MH Agriculture's automated machinery. Contact us today to learn more and start your journey toward more efficient and sustainable agriculture.",
    },

    livestockmonitoring: {
        imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Livestock%20Monitoring/LM01%20-%20Out%20Cover.jpg",
        title: "Livestock Management",
        titleSpan: "Farming",
        subTitle: "Enhance Your Herd with MH Agriculture",
        subTitleSpan: "MH Agro",
        intro: "Welcome to a new era of livestock farming with MH Agriculture's cutting-edge livestock management technology. Our innovative solutions help you monitor, manage, and optimize your herd with ease. By leveraging advanced technology, you can improve animal health, increase productivity, and ensure sustainability in your livestock operations.",
        gridImages: [
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Livestock%20Monitoring/LM%2001.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Livestock%20Monitoring/LM%2002.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Livestock%20Monitoring/LM%2003.jpg"
            },
        ],
        whatIs: "What is",
        whatIsSpan: "Farming?",
        whatIsIntro: "Livestock management involves the comprehensive care and supervision of farm animals to ensure their health, productivity, and well-being. This includes a variety of practices such as feeding, breeding, housing, and healthcare. Advanced livestock management uses modern technologies like GPS tracking, RFID tagging, automated feeding systems, and health monitoring sensors to optimize these practices.",
        circleImg: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Livestock%20Monitoring/LM%2001%20-%20Out%20.png",
        colourBoxes: [
            {
                bgColour: "#9ED442",
                title: "Monitor Animal Health",
                description: "Track the health and well-being of your animals in real-time.",
            },
            {
                bgColour: "#1DFA75",
                title: "Increase Efficiency",
                description: "Automate routine tasks to save time and reduce labor costs.",
            },
            {
                bgColour: "#9ED442",
                title: "Enhance Productivity",
                description: "Maximize output with reliable, high-performa.",
            },
            {
                bgColour: "#1DFA75",
                title: "Promote Sustainability",
                description: "Maximize output with reliable, high-performance machinery.",
            },
        ],
        blackBoxHeading: "",
        blackBoxGrid: [
            {
                title: "Autonomous Tractors",
                description: "Our autonomous tractors are equipped with advanced GPS and sensor technology, allowing them to navigate fields with precision. These tractors can perform tasks such as plowing, planting, and harvesting without human intervention, ensuring consistent and efficient operations.",
            },
            {
                title: "Automated Planters&Seeders",
                description: "Achieve perfect planting with our automated planters and seeders. These machines ensure precise seed placement and depth, optimizing plant growth and yield. Automated planters and seeders reduce labor costs and improve planting efficiency.",
            },
            {
                title: "Robotic Harvesters",
                description: "Speed up your harvesting process with our robotic harvesters. Designed to handle various crops, these machines can quickly and accurately harvest produce, reducing the time and labor required. Robotic harvesters minimize crop damage and increase harvesting efficiency.",
            },
        ],
        ourCommitment: "Our Commitment to",
        ourCommitmentSpan: "Society",
        ourCommitmentIntro: "At MH Agriculture, we are dedicated to promoting sustainable farming practices. Our livestock management technology not only boosts your productivity but also helps you reduce your environmental footprint. By using resources more efficiently and minimizing waste, you contribute to a more sustainable future for agriculture.",
        join: "Join the",
        joinSpan1: "Livestock Farming",
        joinSpan2: "Revolution",
        joinIntro: "Ready to enhance your livestock farming? Join the growing number of farmers who are transforming their operations with MH Agriculture's livestock management technology. Contact us today to learn more and start your journey toward more efficient and sustainable livestock farming",
    },

    farmmanagement: {
        imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Farm%20Management/FMS%2001%20-%20Out%20Cover.jpg",
        title: "Farm & Management",
        titleSpan: "Software",
        subTitle: "Streamline Your Operations with",
        subTitleSpan: "MH Agriculture",
        intro: "Welcome to the future of agriculture with MH Agriculture's farm management software. Our innovative solutions empower farmers to streamline operations, enhance productivity, and promote sustainability. Farm management software is essential for revolutionizing your agricultural practices, optimizing resource use, and ensuring efficient farm management.",
        gridImages: [
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Farm%20Management/FMS%2001.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Farm%20Management/FMS%2002.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Farm%20Management/FMS%2003.jpg"
            },
        ],
        whatIs: "What is Farm Management",
        whatIsSpan: "Software?",
        whatIsIntro: "Farm management software involves the use of digital tools and platforms to manage various aspects of farm operations. From crop planning and monitoring to resource allocation and financial management, this software provides comprehensive solutions to streamline your farming activities. By leveraging real-time data and analytics, farmers can make informed decisions that enhance efficiency and productivity.",
        circleImg: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Farm%20Management/FMS%2001%20-%20Out.png",
        colourBoxes: [
            {
                bgColour: "#9ED442",
                title: "Optimize Operations",
                description: "Streamline all aspects of farm management, from planting to harvesting, using a single platform.",
            },
            {
                bgColour: "#1DFA75",
                title: "Enhance Productivity",
                description: "Utilize data-driven insights to improve crop planning, resource allocation, and overall farm performance.",
            },
            {
                bgColour: "#9ED442",
                title: "Reduce Costs",
                description: "Minimize waste and optimize resource use, leading to significant cost savings.",
            },
            {
                bgColour: "#1DFA75",
                title: "Promote Sustainability",
                description: "Implement sustainable farming practices by monitoring and managing resources more efficiently.",
            },
        ],
        blackBoxHeading: "Our Farm Management Solutions",
        blackBoxGrid: [
            {
                title: "Crop Planning and Monitoring",
                description: "Use our software to plan and monitor your crops effectively. Track growth stages, monitor soil health, and manage planting schedules with ease. Our crop planning tools help you optimize yields and ensure timely interventions for crop health.",
            },
            {
                title: "Resource Management",
                description: "Efficiently manage resources such as water, fertilizers, and pesticides. Our software provides real-time data on resource usage, enabling you to allocate inputs precisely where needed. This reduces waste and ensures optimal resource utilization..",
            },
            {
                title: "Financial Management",
                description: "Keep track of your farm&#39;s financial performance with our comprehensive financial management tools. Monitor expenses, manage budgets, and generate financial reports to make informed decisions. Our software helps you maintain financial health and profitability.",
            },
        ],
        ourCommitment: "Our Commitment to",
        ourCommitmentSpan: "Sustainability",
        ourCommitmentIntro: "At MH Agriculture, we are dedicated to promoting sustainable farming practices. Our farm management software not only enhances your productivity but also helps you reduce your environmental footprint. By using resources more efficiently and minimizing waste, you can contribute to a more sustainable future for agriculture.",
        join: "Join the",
        joinSpan1: "Farm Management",
        joinSpan2: "Revolution",
        joinIntro: "Ready to take your farming operations to the next level? Join the growing number of farmers who are transforming their farms with MH Agriculture's farm management software. Contact us today to learn more and start your journey toward more efficient and sustainable agriculture.",
    },

    remotesensing: {
        imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Remote%20Sensing/R&SM%20-%20Out%20Cover.jpg",
        title: "Remote Sensing and Monitoring",
        titleSpan: "Farming",
        subTitle: "Revolutionize Your Agriculture with",
        subTitleSpan: "MH Agriculture",
        intro: "Welcome to the future of agriculture with MH Agriculture's remote sensing and monitoring solutions. Our cutting-edge technologies enable farmers to gain valuable insights, optimize resource use, and enhance crop management. Remote sensing and monitoring are pivotal in transforming your agricultural practices, improving productivity, and promoting sustainable farming.",
        gridImages: [
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Remote%20Sensing/R&SM%201.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Remote%20Sensing/R&SM%202.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Remote%20Sensing/R&SM%203.jpg"
            },
        ],
        whatIs: "What is Remote Sensing",
        whatIsSpan: "and Monitoring?",
        whatIsIntro: "Remote sensing and monitoring involve the use of advanced technologies such as satellites, drones, and IoT sensors to gather real-time data on crop conditions, soil health, and environmental factors. By analyzing this data, farmers can make informed decisions to enhance crop growth, manage resources efficiently, and ensure sustainable farming practices.",
        circleImg: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Remote%20Sensing/R&SM%2001%20-%20Out.png",
        colourBoxes: [
            {
                bgColour: "#9ED442",
                title: "Gain Real-Time Insights",
                description: "Access up-to-date information on crop health soil conditions, and environmental factors for better decision-making.",
            },
            {
                bgColour: "#1DFA75",
                title: "Optimize Resource Use",
                description: "Reduce costs by applying water, fertilizers, and pesticides only where and when they are needed.",
            },
            {
                bgColour: "#9ED442",
                title: "Make Informed Decisions",
                description: "Leverage data analytics for real-time insights and better crop management.",
            },
            {
                bgColour: "#1DFA75",
                title: "Promote Sustainability",
                description: "Minimize environmental impact by reducing chemical use and conserving natural resources.",
            },
        ],
        blackBoxHeading: "Our Remote Sensing and Monitoring Solutions",
        blackBoxGrid: [
            {
                title: "Satellite Imaging",
                description: "Leverage high-resolution satellite imagery to monitor large areas of farmland. Identify crop health variations, detect pests and diseases early, and assess soil moisture levels. Satellite imaging provides a comprehensive overview of your fields, enabling you to make strategic decisions.",
            },
            {
                title: "Drone Surveillance",
                description: "Utilize drones equipped with advanced cameras and sensors to capture detailed images of your crops. Drones offer a close-up view of plant health, allowing for precise detection of issues such as nutrient deficiencies, pest infestations,and irrigation needs. This real-time data helps you respond quickly and effectively.",
            },
            {
                title: "IoT Sensors",
                description: "Deploy IoT sensors across your fields to collect continuous data on soil moisture, temperature, and other environmental factors. These sensors provide real-time insights, enabling you to optimize irrigation schedules, monitor soil health, and enhance crop growth. IoT sensors are essential for precise and efficient farming.",
            },
        ],
        ourCommitment: "Our Commitment to",
        ourCommitmentSpan: "Sustainability",
        ourCommitmentIntro: "At MH Agriculture, we are committed to advancing sustainable farming practices. Our remote sensing and monitoring solutions not only enhance your productivity but also help you reduce your environmental footprint. By using resources more efficiently and minimizing waste, you can contribute to a more sustainable future for agriculture.",
        join: "Join the",
        joinSpan1: "Remote Sensing",
        joinSpan2: "and Monitoring",
        joinIntro: "Ready to elevate your farming operations? Join the growing number of farmers who are transforming their agriculture with MH Agriculture's remote sensing and monitoring solutions. Contact us today to learn more and embark on your journey toward more efficient and sustainable farming.",
    },

    soilandcropanalysis: {
        imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Soil%20and%20Crop%20Analysis/S&CHA%2001%20-%20Out%20Cover.jpg",
        title: "Soil and Crop",
        titleSpan: "Health Analysis",
        subTitle: "Nurture Your Farm with",
        subTitleSpan: "MH Agriculture",
        intro: "Welcome to a new level of farming with MH Agriculture's soil and crop health analysis solutions. Our innovative technologies help you understand your soil and crops better, enabling you to make informed decisions that enhance productivity and sustainability. By delving deep into the health of your soil and crops, you can ensure a thriving farm.",
        gridImages: [
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Soil%20and%20Crop%20Analysis/S&CHA%2001.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Soil%20and%20Crop%20Analysis/S&CHA%2002.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Soil%20and%20Crop%20Analysis/S&CHA%2003.jpg"
            },
        ],
        whatIs: "Why Soil and Crop",
        whatIsSpan: "Health Matter",
        whatIsIntro: "Healthy soil and crops are the foundation of successful farming. By regularly analyzing soil and crop health, you can:",
        circleImg: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Soil%20and%20Crop%20Analysis/S&CHA%2001%20-%20Out%20.png",
        colourBoxes: [
            {
                bgColour: "#9ED442",
                title: "Optimize Operations",
                description: "Ensure your crops get the right nutrients for maximum growth and yield.",
            },
            {
                bgColour: "#1DFA75",
                title: "Reduce Costs",
                description: "Apply fertilizers and amendments precisely where needed, reducing waste and expense.",
            },
            {
                bgColour: "#9ED442",
                title: "Prevent Issues",
                description: "Identify and address potential problems before they impact your harvest.",
            },
            {
                bgColour: "#1DFA75",
                title: "Promote Sustainability",
                description: "Use resources wisely and maintain soil health for future generations.",
            },
        ],
        blackBoxHeading: "Our Soil and Crop Health Solutions",
        blackBoxGrid: [
            {
                title: "Comprehensive Soil Testing",
                description: "Our soil testing services provide detailed information on soil composition, pH levels, nutrient content, and more. Understanding your soil&#39;s unique characteristics allows you to tailor your farming practices to improve soil health and crop performance.",
            },
            {
                title: "Nutrient Management",
                description: "Utilize our nutrient management tools to ensure your crops receive the optimal balance of nutrients. By analyzing soil and crop data, we help you create a customized fertilization plan that maximizes growth and minimizes environmental impact.",
            },
            {
                title: "Pest and Disease Monitoring",
                description: "Early detection of pests and diseases is crucial for maintaining crop health. Our monitoring solutions use advanced sensors and imaging technology to identify issues early, allowing you to take swift action to protect your crops.",
            },
        ],
        ourCommitment: "Our Commitment to",
        ourCommitmentSpan: "Sustainability",
        ourCommitmentIntro: "At MH Agriculture, we are dedicated to promoting sustainable farming practices. Our soil and crop health analysis solutions not only boost your productivity but also help you reduce your environmental footprint. By understanding and improving soil and crop health, you contribute to a more sustainable future for agriculture.",
        join: "Join the",
        joinSpan1: "Soil and Crop",
        joinSpan2: "Health Analysis",
        joinIntro: "Ready to nurture your farm to its full potential? Join the growing number of farmers who are transforming their agriculture with MH Agriculture's soil and crop health analysis solutions. Contact us today to learn more and start your journey toward healthier, more productive farming.",
    },

    streamlinedsupplychain: {
        imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Streamlined%20Supply%20Chain/SCMAS%2001%20-%20Out%20Cover.jpg",
        title: "SupplyChain& Market Access",
        titleSpan: "Solutions",
        subTitle: "Streamline Your Path to Market with",
        subTitleSpan: "MH Agriculture",
        intro: "Welcome to the future of agricultural commerce with MH Agriculture's supply chain and market access solutions. Our innovative technologies help farmers streamline their supply chains, access new markets, and maximize profitability. By optimizing your supply chain and enhancing market access, you can ensure your products reach the right customers efficiently and effectively.",
        gridImages: [
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Streamlined%20Supply%20Chain/SCMAS%2001%20-%20Tile.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Streamlined%20Supply%20Chain/SCMAS%2002%20-%20Tile.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Streamlined%20Supply%20Chain/SCMAS%2003%20-%20Tile.jpg"
            },
        ],
        whatIs: "Why Supply Chain and",
        whatIsSpan: "Market Access Matter",
        whatIsIntro: "A well-managed supply chain and effective market access are crucial for the success of any farming operation. By focusing on these areas, you can:",
        circleImg: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Streamlined%20Supply%20Chain/SCMAS%2001.png",
        colourBoxes: [
            {
                bgColour: "#9ED442",
                title: "Reduce Costs",
                description: "Streamline processes to minimize waste and lower operational expenses.",
            },
            {
                bgColour: "#1DFA75",
                title: "Increase Efficiency",
                description: "Ensure timely delivery of products to market, reducing spoilage and losses.",
            },
            {
                bgColour: "#9ED442",
                title: "Access new markets and customers, increasing sales opportunities.",
                description: "",
            },
            {
                bgColour: "#1DFA75",
                title: "Enhance Profitability",
                description: "Optimize pricing and distribution strategies to maximize profits.",
            },
        ],
        blackBoxHeading: "Our Supply Chain and Market Access Solutions",
        blackBoxGrid: [
            {
                title: "Efficient Logistics Management",
                description: "Our logistics management tools help you coordinate and streamline the movement of goods from farm to market. From transportation planning to real-time tracking, our solutions ensure your products reach their destination on time and in optimal condition.",
            },
            {
                title: "Inventory Management",
                description: "Keep track of your inventory with our advanced management systems. Monitor stock levels, manage storage conditions, and optimize inventory turnover to reduce waste and ensure your products are always market-ready.",
            },
            {
                title: "Market Analysis and Access",
                description: "Gain insights into market trends and customer preferences with our market analysis tools. Identify new market opportunities and develop strategies to access and expand your market reach. Our solutions help you tailor your products and marketing efforts to meet customer demands.",
            },
        ],
        ourCommitment: "Our Commitment to",
        ourCommitmentSpan: "Sustainability",
        ourCommitmentIntro: "At MH Agriculture, we are dedicated to promoting sustainable farming practices. Our supply chain and market access solutions not only enhance your profitability but also help you reduce your environmental footprint. By optimizing supply chain processes and reducing waste, you contribute to a more sustainable future for agriculture.",
        join: "Join the",
        joinSpan1: "Supply Chain",
        joinSpan2: "and Market Access Revolution",
        joinIntro: "Ready to streamline your path to market? Join the growing number of farmers who are transforming their operations with MH Agriculture's supply chain and market access solutions. Contact us today to learn more and start your journey toward more efficient and profitable farming.",
    },

    greenhouseautomation: {
        imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Greenhouse%20Automation/GA%2001%20-%20Out%20Cover.jpg",
        title: "Greenhouse",
        titleSpan: "Automation",
        subTitle: "Cultivate Excellence with",
        subTitleSpan: "MH Agriculture",
        intro: "Welcome to the future of greenhouse farming with MH Agriculture's advanced automation solutions. Our cutting-edge technologies empower farmers to optimize growing conditions, enhance productivity, and ensure sustainability. Greenhouse automation is revolutionizing the way you grow, allowing you to achieve consistent and high-quality yields.",
        gridImages: [
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Greenhouse%20Automation/GA%201.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Greenhouse%20Automation/GA%202.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Greenhouse%20Automation/GA%203.jpg"
            },
        ],
        whatIs: "What is Greenhouse",
        whatIsSpan: "Automation?",
        whatIsIntro: "Greenhouse automation involves using advanced systems and technologies to control and monitor the growing environment within a greenhouse. Automated systems manage factors such as temperature, humidity, light, and irrigation, ensuring optimal conditions for plant growth. By automating these processes, farmers can achieve precise control and improve efficiency.",
        circleImg: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Greenhouse%20Automation/GA%2001%20-%20Out.png",
        colourBoxes: [
            {
                bgColour: "#9ED442",
                title: "Optimize Growing Conditions:",
                description: "Maintain ideal environmental conditions for plant growth, ensuring healthy and productive crops.",
            },
            {
                bgColour: "#1DFA75",
                title: "Increase Efficiency",
                description: "Automate routine tasks to save time and labor, allowing you to focus on more critical activities.",
            },
            {
                bgColour: "#9ED442",
                title: "Enhance Productivity",
                description: "Achieve consistent yields and improve crop quality with precise control over the growing environment.",
            },
            {
                bgColour: "#1DFA75",
                title: "Promote Sustainability",
                description: "Reduce resource use and minimize waste by optimizing water, energy, and nutrient application.",
            },
        ],
        blackBoxHeading: "Our Greenhouse Automation Solutions",
        blackBoxGrid: [
            {
                title: "Climate Control Systems",
                description: "Our climate control systems automate the regulation of temperature, humidity, and CO2 levels within your greenhouse. These systems use sensors and controllers to maintain optimal conditions, promoting healthy plant growth and reducing energy consumption.",
            },
            {
                title: "Automated Irrigation",
                description: "Implement automated irrigation systems to deliver the right amount of water at the right time. Our systems use soil moisture sensors and weather data to adjust irrigation schedules, ensuring efficient water use and preventing over-or under-watering.",
            },
            {
                title: "Lighting Automation",
                description: "Optimize lighting conditions with automated lighting systems. These systems adjust light intensity and duration based on plant needs and natural light availability, promoting optimal growth and energy efficiency.",
            },
        ],
        ourCommitment: "Our Commitment to",
        ourCommitmentSpan: "Sustainability",
        ourCommitmentIntro: "At MH Agriculture, we are dedicated to promoting sustainable farming practices. Our greenhouse automation solutions not only boost your productivity but also help you reduce your environmental footprint. By using resources more efficiently and minimizing waste, you contribute to a more sustainable future for agriculture.",
        join: "Join the",
        joinSpan1: "Greenhouse Automation",
        joinSpan2: "Revolution",
        joinIntro: "Ready to elevate your greenhouse farming? Join the growing number of farmers who are transforming their operations with MH Agriculture's greenhouse automation solutions. Contact us today to learn more and start your journey toward more efficient and sustainable greenhouse farming.",
    },

    agriculturaldrones: {
        imgSrc: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Agricultural%20Drones/AD%2001%20-%20Out%20Cover%20.jpg",
        title: "Agricultural Drones",
        titleSpan: "Farming",
        subTitle: "Innovate Your Farming with",
        subTitleSpan: "MH Agro",
        intro: "Welcome to the new era of agriculture with MH Agriculture's advanced agricultural drones. Our state-of-the-art drone technology empowers farmers to optimize crop management, enhance efficiency, and promote sustainability. Agricultural drones are revolutionizing farming by providing precise data and actionable insights.",
        gridImages: [
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Agricultural%20Drones/Agricultural%20Drones%2002.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Agricultural%20Drones/Agricultural%20Drones%2001.jpg"
            },
            {
                src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Agricultural%20Drones/Agricultural%20Drones%2003.jpg"
            },
        ],
        whatIs: "What is Drones",
        whatIsSpan: "Farming?",
        whatIsIntro: "Agricultural drones are unmanned aerial systems (UAS) equipped with sophisticated sensors and imaging technologies. These drones capture high-resolution images and collect data on crop conditions, soil health, and environmental factors. By leveraging this data, farmers can make informed decisions to improve yields, manage resources, and implement sustainable practices.",
        circleImg: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Agricultural%20Drones/AD%2001%20-%20Out.png",
        colourBoxes: [
            {
                bgColour: "#9ED442",
                title: "Accurate Crop Monitoring",
                description: "Utilize precise data to ensure optimal plant growth and increase your harvest.",
            },
            {
                bgColour: "#1DFA75",
                title: "Resource Optimization",
                description: "Reduce costs by applying water, fertilizers, and pesticides only where and when they are needed.",
            },
            {
                bgColour: "#9ED442",
                title: "Early Problem Detection",
                description: "Leverage data analytics for real-time insights and better crop management.",
            },
            {
                bgColour: "#1DFA75",
                title: "Sustainability",
                description: "Reduce environmental impact through efficient resource use and targeted treatments.",
            },
        ],
        blackBoxHeading: "",
        blackBoxGrid: [
            {
                title: "High-Resolution Imaging",
                description: "Utilize our drones for high-resolution aerial imagery. This technology allows for comprehensive monitoring of crop health, detecting variations.",
            },
            {
                title: "Multispectral Analysis",
                description: "Employ multispectral sensors to capture data beyond the visible spectrum. Analyze plant health indicators such as chlorophyll content and plant stress levels. Multispectral analysis enables precise application of inputs, improving crop health and yield.",
            },
            {
                title: "Precision Agriculture",
                description: "Implement precision agriculture practices with drone technology. Our drones facilitate variable rate application of inputs, ensuring that each part of the field receives the exact amount of water, fertilizers, or pesticides it needs. This precision reduces waste and enhances crop productivity.",
            },
        ],
        ourCommitment: "Our Commitment to",
        ourCommitmentSpan: "Society",
        ourCommitmentIntro: "At MH Agriculture, we are committed to advancing sustainable farming practices. Our agricultural drone solutions not only enhance your productivity but also help you reduce your environmental footprint. By using resources more efficiently and minimizing waste, you contribute to a more sustainable future for agriculture.",
        join: "Join the",
        joinSpan1: "Agricultural Drone",
        joinSpan2: "Revolution",
        joinIntro: "Ready to innovate your farming operations? Join the growing number of farmers who are transforming their agriculture with MH Agriculture's advanced drone solutions. Contact us today to learn more and start your journey toward more efficient and sustainable farming.",
    },

}