export const ServicesBox = [

    {
        id: "precisionagriculture",
        src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Precision%20Agriculture.png",
        title: "Precision Agriculture",
        description: "MH Agro uses GPS-guided machinery and VRT to maximize crop yields and sustainability through precise farming practices.",
    },
    {
        id: "smartirrigation",
        src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Smart%20Irrigation.png",
        title: "Smart Irrigation",
        description: "Our smart irrigation systems conserve water with automated solutions, soil moisture sensors, and weather-based scheduling.",
    },
    {
        id: "automatedmachinery",
        src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Automated%20Machinery.png",
        title: "Automated Machinery",
        description: "Our automated machinery solutions reduce labor costs and increase efficiency, allowing farmers to focus on critical tasks.",
    },
    {
        id: "livestockmonitoring",
        src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Livestock%20Monitoring.png",
        title: "Livestock Monitoring",
        description: "MH Agro livestock management technology improves animal health, productivity, and overall farm efficiency.",
    },
    {
        id: "farmmanagement",
        src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Farm%20Management%20Software.png",
        title: "Farm Management",
        description: "MH Agro farm management software streamlines operations with tools for crop planning, inventory management, and financial oversight.",
    },
    {
        id: "remotesensing",
        src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Remote%20sensing.png",
        title: "Remote Sensing",
        description: "MH Agro offers remote sensing and monitoring solutions with real-time field data from satellite imaging, drones, and IoT sensors.",
    },
    {
        id: "soilandcropanalysis",
        src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Soil%20and%20Crop%20Analysis.png",
        title: "Soil and Crop Analysis",
        description: "MH Agro provides advanced soil and crop health analysis using cutting-edge technology for optimal yield.",
    },
    {
        id: "streamlinedsupplychain",
        src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Supply%20Chain%20Management.png",
        title: "Streamlined Supply Chain",
        description: "MH Agro connects farmers to markets efficiently, ensuring better pricing, reduced wastage, and improved profitability.",
    },
    {
        id: "greenhouseautomation",
        src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Greenhouse%20Automation.png",
        title: "Greenhouse Automation",
        description: "Optimize indoor farming with MH Agro greenhouse automation featuring climate control and automated nutrient delivery.",
    },
    {
        id: "agriculturaldrones",
        src: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Services/Agricultural%20Drones.png",
        title: "Agricultural Drones",
        description: "MH Agro agricultural drones offer precise crop spraying, field mapping, and pest and disease detection.",
    },

]