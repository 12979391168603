import React, { useState } from "react";
import Navbar from "./Components/Header/Navbar";
import ScrollToTop from "./ScrollToTop";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Footer from "./Components/Footer/Footer";
import AboutUs from "./Pages/About Us/AboutUs";
import Services from "./Pages/Services/Services";
import { ServicesDetailedPages } from "./Pages/Services/Pages/ServicesDetailedPages";
import OurGovernance from "./Pages/Our Governance/OurGovernance";
import ContactUs from "./Pages/Contact Us/ContactUs";

function App() {

  const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);

  return (
    <div className="overflow-hidden">
      <Navbar setBackgroundBlur={setIsBackgroundBlurred} />
      <div id="background" className={`xl:pt-12 max-sm:pt-12 ${isBackgroundBlurred ? '' : ''}`}>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/about"
            element={<AboutUs />}
          />
          <Route
            path="/services"
            element={<Services />}
          />
          <Route
            path="/service/:serviceKey"
            element={<ServicesDetailedPages />}
          />
          <Route
            path="/ourgoverance"
            element={<OurGovernance />}
          />
          <Route
            path="/contact"
            element={<ContactUs />}
          />
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
