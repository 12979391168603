import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

const contentData = [
  {
    title: "Boost Your Farming Efficiency",
    description: "At MHAgro, we're committed to enhancing your farming efficiency through advanced technologies like GPS-guided machinery, smart irrigation systems, and state-of-the-art drones. Our solutions not only maximize crop yields and sustainability but also streamline operations, saving you time and costs.",
    image: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/whychoose%20(3).jpg"
  },
  {
    title: "Empowering Farmers with Innovation",
    description: "Choose MHAgro for innovative solutions that empower you to succeed in modern agriculture. From comprehensive farm management software to real-time remote sensing technologies, we provide the tools you need to make informed decisions and optimize your farm's performance.",
    image: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/whychoose%20(4).jpg"
  },
  {
    title: "Sustainable Farming Practices Made Easy",
    description: "Join MHAgro in promoting sustainable farming practices effortlessly. Our smart irrigation systems and greenhouse automation solutions conserve water and energy while enhancing crop health. Together, we can build a greener future for agriculture.",
    image: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/whychoose%20(2).jpg"
  },
  {
    title: "Precision Farming for Greater Profitability",
    description: "MHAgro offers precision farming techniques that ensure optimal resource use and maximize your profitability. With VRT and advanced drone technology for crop management, we help you reduce input costs and achieve higher yields sustainably.",
    image: "https://ik.imagekit.io/mhcockpit1/MH%20Agro/Home/whychoose%20(1).jpg"
  }
];

const Innovation = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.3,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    // return () => {
    //   if (containerRef.current) {
    //     observer.unobserve(containerRef.current);
    //   }
    // };

  }, []);

  useEffect(() => {
    if (isSticky && !isHovered) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % contentData.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isSticky, isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="mt-5">
      <div className="p-20 md:p-10 max-sm:p-5">
        <div
          className="relative"
          ref={containerRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <motion.div
            className="flex flex-col md:flex-row bg-white bg-opacity-80 rounded-lg transition-all duration-300"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex-1 p-5 flex flex-col justify-center items-start space-y-3">
              <h1 className="text-2xl font-bold text-start">{contentData[currentIndex].title}</h1>
              <p className="text-base max-sm:text-justify">{contentData[currentIndex].description}</p>
            </div>
            <div className="flex-1 flex justify-center items-center">
              <img
                src={contentData[currentIndex].image}
                alt={contentData[currentIndex].title}
                className="w-full h-full rounded-lg"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Innovation;
